import { render, staticRenderFns } from "./index.vue?vue&type=template&id=93f1e3e2"
import script from "./index.vue?vue&type=script&setup=true&lang=ts"
export * from "./index.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ChromeNotification: require('/app/components/Chrome/ChromeNotification.vue').default})
